import React from 'react'
import Layout from "../views/layout";


const TermsAndConditions = (props) => {
    return (
        <Layout>
            <section className="home-practices1">
            <span className="terms-and-conditions-text">
              <span>TERMS AND SERVICE CONDITIONS</span>
              <br></br>
              <span> of J &amp; B POWER WASH</span>
              <br></br>
            </span>
                <span className="terms-and-conditions-text005">
              <span>
                1. INITIAL INTERPRETATION
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text007"></br>
              <br className="terms-and-conditions-text008"></br>
              <span className="terms-and-conditions-text009">
                1.1 In this document, &quot;the Service Provider&quot; refers to
                J &amp; B Power Wash offering pressure washing services, and
                shall include its employees and authorized representatives.
                  &quot;The Client&quot; refers to the person authorizing the
                pressure washing service and any person on whom authority is
                conferred by the Client.
              </span>
              <br className="terms-and-conditions-text010"></br>
              <br className="terms-and-conditions-text011"></br>
              <span>
                2. LEGALLY BINDING AGREEMENT
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text013"></br>
              <br className="terms-and-conditions-text014"></br>
              <span className="terms-and-conditions-text015">
                2.1 By accepting the quote provided by the service provider, you
                are entering into a legally binding agreement for work to be
                completed at an agreed-upon price. In the event of contract
                termination, all deposits made to the company shall be
                surrendered as damages.
              </span>
              <br className="terms-and-conditions-text016"></br>
              <br className="terms-and-conditions-text017"></br>
              <span>
                3. PRODUCT WARRANTIES
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text019"></br>
              <br className="terms-and-conditions-text020"></br>
              <span className="terms-and-conditions-text021">
                3.1 All warranties are limited to those offered by the
                manufacturers of the products used. The service provider makes
                no additional warranties. If you ever have a concern regarding
                our work, the service provider should be notified immediately.
              </span>
              <br className="terms-and-conditions-text022"></br>
              <br className="terms-and-conditions-text023"></br>
              <span>4. Water Usage</span>
              <br className="terms-and-conditions-text025"></br>
              <br className="terms-and-conditions-text026"></br>
              <span className="terms-and-conditions-text027">
                4.1 By signing this agreement, you agree to provide the service
                provider the right to use an on-site water supply as needed to
                complete the stated project without compensation. If an exterior
                water supply is required, it will be at an additional charge. It
                is the customer’s responsibility to make sure the water supply
                is on and in working order before we arrive. Additional charges
                will be applied if water is not available.
              </span>
              <br className="terms-and-conditions-text028"></br>
              <br className="terms-and-conditions-text029"></br>
              <span>
                5. Electrical Usage:
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text031"></br>
              <br className="terms-and-conditions-text032"></br>
              <span className="terms-and-conditions-text033">
                5.1 By signing this agreement, you agree to provide the service
                provider the right to use an on-site source of electricity as
                needed to complete the stated project without compensation. If
                an exterior source is required, it will be at an additional
                charge.
              </span>
              <br className="terms-and-conditions-text034"></br>
              <br className="terms-and-conditions-text035"></br>
              <span>6. Color and Tone Concerns</span>
              <br className="terms-and-conditions-text037"></br>
              <br className="terms-and-conditions-text038"></br>
              <span className="terms-and-conditions-text039">
                6.1 The properties and species of wood age and weather can
                greatly affect the resulting color or tone of the stain. Note:
                Variances may occur on individual boards as well as the total
                project, as densities and other characteristics vary across and
                throughout the wood. The service provider and its associates
                attempt to represent the final finish color and tones as best as
                possible. While we can often give you an idea of the overall
                color or tone you must expect some variance in the overall
                finish.
              </span>
              <br className="terms-and-conditions-text040"></br>
              <br className="terms-and-conditions-text041"></br>
              <span>
                7. Stains
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text043"></br>
              <br className="terms-and-conditions-text044"></br>
              <span className="terms-and-conditions-text045">
                7.1 Some stains cannot be removed by power washing. Tree sap,
                artillery fungus, splatters from stains and paints are examples
                of materials that cannot be removed by conventional means. We
                make every attempt to point these areas out to the customer when
                quoting the project. Sometimes these stains cannot be removed at
                all.
              </span>
              <br className="terms-and-conditions-text046"></br>
              <br className="terms-and-conditions-text047"></br>
              <span>8. Courtesy</span>
              <br className="terms-and-conditions-text049"></br>
              <br className="terms-and-conditions-text050"></br>
              <span className="terms-and-conditions-text051">
                8.1 While the service provider is on location and performing
                work on your property, you are responsible for keeping all
                children and pets, as well as other individuals away from the
                work area. Children and pets must be kept off the work surface
                for at least 24 hours after our work is completed. This is for
                your safety as well as our own.
              </span>
              <br className="terms-and-conditions-text052"></br>
              <br className="terms-and-conditions-text053"></br>
              <span>
                9. BOOKING AND VARIATIONS
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text055"></br>
              <br className="terms-and-conditions-text056"></br>
              <span className="terms-and-conditions-text057">
                9.1 Services provided by the service provider may be subject to
                variation by mutual agreement between the service provider and
                the Client. Any changes must be agreed upon in writing.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text058"></br>
              <br className="terms-and-conditions-text059"></br>
              <span className="terms-and-conditions-text060">
                9.2 To secure a booking, the Client must pay in advance a
                non-refundable amount of 25% of the total quoted for the
                service. In the event that the Client needs to reschedule or
                cancel the job, this advance payment will not be refunded but
                may be applied towards a future booking, subject to availability
                and agreement by the service provider.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text061"></br>
              <br className="terms-and-conditions-text062"></br>
              <span className="terms-and-conditions-text063">
                9.3 The Client acknowledges that variations to the service may
                occur once the job has started due to conditions of the surface
                area. These variations may necessitate additional labor,
                equipment, treatments, chemicals, or other resources to achieve
                the best result for the service. Any additional costs incurred
                due to such variations will be communicated to the Client and
                must be agreed upon before proceeding with the service.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text064"></br>
              <br className="terms-and-conditions-text065"></br>
              <span className="terms-and-conditions-text066">
                9.4 Scheduling in a business which productivity relies upon the
                weather can be difficult. Inclement weather may affect
                scheduling. We try our best to keep scheduling conflicts to a
                minimum, however, circumstances that are beyond our control may
                affect your project start and completion dates. You will be
                notified of any changes.
              </span>
              <br className="terms-and-conditions-text067"></br>
              <br className="terms-and-conditions-text068"></br>
              <span>
                10. SERVICE PROVISION AND OBLIGATIONS
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text070"></br>
              <br className="terms-and-conditions-text071"></br>
              <span className="terms-and-conditions-text072">
                10.1 The service provider reserves the right to refuse service
                to any Client for any reason, including but not limited to
                unsafe working conditions (such as hostile behavior towards the
                service provider&apos;s staff), or where the requested service
                poses a risk to the safety of personnel or property.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text073"></br>
              <br className="terms-and-conditions-text074"></br>
              <span className="terms-and-conditions-text075">
                10.2 The service provider will execute pressure washing services
                diligently and professionally, employing appropriate methods and
                equipment to achieve satisfactory results.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text076"></br>
              <br className="terms-and-conditions-text077"></br>
              <span className="terms-and-conditions-text078">
                10.3 The Client acknowledges that accurate information regarding
                the area to be pressure washed is essential. Failure to provide
                accurate information may result in termination of the service
                without refund.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text079"></br>
              <br className="terms-and-conditions-text080"></br>
              <span className="terms-and-conditions-text081">
                10.4 Removal and replacement of grills, deck furniture,
                planters, and any other items are the responsibility of the
                homeowner. Should we need to remove items from the deck, we will
                not be responsible for any damage, breakage or for storage
                issues. An additional charge may be applied for the time and
                labor devoted to the removal of these items.
              </span>
              <br className="terms-and-conditions-text082"></br>
              <br className="terms-and-conditions-text083"></br>
              <span>
                11. LIABILITY AND INDEMNITY
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text085"></br>
              <br className="terms-and-conditions-text086"></br>
              <span className="terms-and-conditions-text087">
                11.1 The Client confirms ownership of the property to be
                pressure washed and warrants authority to engage the service
                provider for the said service. The Client indemnifies the
                service provider against any claim arising from a breach of this
                warranty.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text088"></br>
              <br className="terms-and-conditions-text089"></br>
              <span className="terms-and-conditions-text090">
                11.2 The service provider shall not be liable for any damage
                caused by the presence of hazardous materials or items not
                disclosed by the Client prior to service commencement.
              </span>
              <br className="terms-and-conditions-text091"></br>
              <br className="terms-and-conditions-text092"></br>
              <span>
                12. PAYMENT TERMS
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text094"></br>
              <br className="terms-and-conditions-text095"></br>
              <span className="terms-and-conditions-text096">
                12.1 All payments for pressure washing services by the service
                provider are due upon completion of the service unless otherwise
                agreed upon in writing.
              </span>
              <br className="terms-and-conditions-text097"></br>
              <br className="terms-and-conditions-text098"></br>
              <span className="terms-and-conditions-text099">
                12.2 The Client shall be responsible for any additional charges
                incurred due to variations or extensions of the service beyond
                the agreed scope.
              </span>
              <br className="terms-and-conditions-text100"></br>
              <br className="terms-and-conditions-text101"></br>
              <span className="terms-and-conditions-text102">
                12.3 Payments to the service provider are due as per the
                contract schedule and are to be paid by bank transfer, direct
                deposit, or cash. All balances are ALWAYS due upon completion of
                the job. Any variance to this policy must be agreed upon and in
                writing on our contract. Late charges will be immediately
                assessed on all balances not paid in accordance with contract
                terms. The customer agrees to pay any collection cost incurred
                by the service provider related to the collection process of
                outstanding balances.
              </span>
              <br className="terms-and-conditions-text103"></br>
              <br className="terms-and-conditions-text104"></br>
              <span>
                13. DAMAGES AND CLAIMS
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text106"></br>
              <br className="terms-and-conditions-text107"></br>
              <span className="terms-and-conditions-text108">
                13.1 The service provider shall exercise due care in the
                execution of pressure washing services. However, the Client must
                inspect the completed work before the service provider leaves
                the premises.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text109"></br>
              <br className="terms-and-conditions-text110"></br>
              <span className="terms-and-conditions-text111">
                13.2 Any claims for damages must be reported to the service
                provider before departure from the premises. Failure to report
                damages promptly may result in the claim being null and void.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text112"></br>
              <br className="terms-and-conditions-text113"></br>
              <span className="terms-and-conditions-text114">
                13.3 The service provider shall not be liable for damages caused
                by pre-existing conditions, negligence of the Client, or
                unforeseeable circumstances beyond reasonable control.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text115"></br>
              <br className="terms-and-conditions-text116"></br>
              <span className="terms-and-conditions-text117">
                13.4 The service provider is not responsible for damages due to
                improperly installed siding, loose shingles or siding, broken or
                opened windows, improperly sealed windows and doors, wood rot,
                defective construction, improperly secured wires, lose or
                improperly installed gutters, and leaders and improper caulking.
                In every aluminum siding case and in some cases with vinyl
                siding, the sun and weather will bleach the color or cause
                fading. Power washing, which entails the removal of chalky,
                gritty, or failing surface materials may cause the faded aspects
                of the vinyl or aluminum to stand out. The service provider will
                not be responsible for such conditions. The service provider
                will not be responsible for loose mortar that may dislodge
                during the cleaning process.
              </span>
              <br className="terms-and-conditions-text118"></br>
              <br className="terms-and-conditions-text119"></br>
              <span>14. Watertight</span>
              <br className="terms-and-conditions-text121"></br>
              <br className="terms-and-conditions-text122"></br>
              <span className="terms-and-conditions-text123">
                14.1 The service provider expects your property to be in good
                repair and watertight. This includes, but is not limited to all
                electrical services including receptacles and light textures.
                Doors and windows shall also be watertight. The service provider
                is not responsible for damages as a result of water infiltration
                from poor or improper installation, maintenance, or repair of
                electrical-related items or doors or windows. The service
                provider cannot guarantee removal of artillery fungus from
                exterior house surfaces.
              </span>
              <br className="terms-and-conditions-text124"></br>
              <br className="terms-and-conditions-text125"></br>
              <span>
                15. Window Spotting:
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text127"></br>
              <br className="terms-and-conditions-text128"></br>
              <span className="terms-and-conditions-text129">
                15.1 Windows may become water spotted as a result of our
                services. Window cleaning is NOT included unless previously
                agreed upon as part of the service.
              </span>
              <br className="terms-and-conditions-text130"></br>
              <br className="terms-and-conditions-text131"></br>
              <span>16. Concrete</span>
              <br className="terms-and-conditions-text133"></br>
              <br className="terms-and-conditions-text134"></br>
              <span className="terms-and-conditions-text135">
                16.1 The appearance of concrete depends on a number of factors
                most linked to when the concrete was initially poured and cured.
                When concrete with dirt, mold, mildew, algae and other
                pollutants and stains is cleaned, the concrete will then reveal
                any and all imperfections that the pollutants and stains have
                covered up. The customer understands that not all concrete will
                look the same even on the same driveway, patio, sidewalk, or
                concrete slab.
              </span>
              <br className="terms-and-conditions-text136"></br>
              <br className="terms-and-conditions-text137"></br>
              <span>17. INSURANCE</span>
              <br className="terms-and-conditions-text139"></br>
              <br className="terms-and-conditions-text140"></br>
              <span className="terms-and-conditions-text141">
                17.1 The service provider maintains appropriate insurance
                coverage for liability arising from its operations.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text142"></br>
              <br className="terms-and-conditions-text143"></br>
              <span className="terms-and-conditions-text144">
                17.2 The Client acknowledges that the service provider&apos;s
                insurance may not cover certain items or damages and is
                encouraged to maintain separate insurance coverage as necessary.
              </span>
              <br className="terms-and-conditions-text145"></br>
              <br className="terms-and-conditions-text146"></br>
              <span>18. TERMINATION</span>
              <br className="terms-and-conditions-text148"></br>
              <br className="terms-and-conditions-text149"></br>
              <span className="terms-and-conditions-text150">
                18.1 Either party may terminate the agreement for pressure
                washing services upon written notice if the other party breaches
                any material provision of these terms and conditions.
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text151"></br>
              <br className="terms-and-conditions-text152"></br>
              <span className="terms-and-conditions-text153">
                18.2 In the event of termination, the Client shall pay for all
                services provided up to the termination date.
              </span>
              <br className="terms-and-conditions-text154"></br>
              <br className="terms-and-conditions-text155"></br>
              <span>
                19. GOVERNING LAW
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text157"></br>
              <br className="terms-and-conditions-text158"></br>
              <span className="terms-and-conditions-text159">
                19.1 These terms and conditions shall be governed by and
                construed in accordance with the laws of Queensland Government.
              </span>
              <br className="terms-and-conditions-text160"></br>
              <br className="terms-and-conditions-text161"></br>
              <span>
                20. ACCEPTANCE
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text163"></br>
              <br className="terms-and-conditions-text164"></br>
              <span className="terms-and-conditions-text165">
                20.1 By engaging the services of the service provider, the
                Client agrees to be bound by these terms and conditions.
              </span>
              <br className="terms-and-conditions-text166"></br>
              <br className="terms-and-conditions-text167"></br>
              <span>
                21. MODIFICATIONS
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text169"></br>
              <br className="terms-and-conditions-text170"></br>
              <span className="terms-and-conditions-text171">
                21.1 These terms and conditions may only be modified in writing
                and signed by both parties.
              </span>
              <br className="terms-and-conditions-text172"></br>
              <br className="terms-and-conditions-text173"></br>
              <span>22. SEVERABILITY</span>
              <br className="terms-and-conditions-text175"></br>
              <br className="terms-and-conditions-text176"></br>
              <span className="terms-and-conditions-text177">
                22.1 If any provision of these terms and conditions is found to
                be invalid or unenforceable, the remaining provisions shall
                remain in full force and effect.
              </span>
              <br className="terms-and-conditions-text178"></br>
              <br className="terms-and-conditions-text179"></br>
              <span>23. ENTIRE AGREEMENT</span>
              <br className="terms-and-conditions-text181"></br>
              <br className="terms-and-conditions-text182"></br>
              <span className="terms-and-conditions-text183">
                23.1 These terms and conditions constitute the entire agreement
                between the parties concerning the subject matter herein and
                supersede all prior agreements and understandings, whether oral
                or written.
              </span>
              <span>
                <span
                    dangerouslySetInnerHTML={{
                        __html: ' ',
                    }}
                />
              </span>
              <br className="terms-and-conditions-text185"></br>
              <br className="terms-and-conditions-text186"></br>
              <br className="terms-and-conditions-text187"></br>
              <br className="terms-and-conditions-text188"></br>
              <span className="terms-and-conditions-text189">
                By booking pressure washing services with J &amp; B Power Wash,
                the Client acknowledges having read, understood, and accepted
                these terms and conditions in their entirety.
              </span>
              <br className="terms-and-conditions-text190"></br>
              <br className="terms-and-conditions-text191"></br>
              <br className="terms-and-conditions-text192"></br>
              <span>
                THIS TERMS AND SERVICE CONDITIONS WAS UPDATED ON 20 OF FEBRUARY
                OF 2024 FOR LAST TIME
              </span>
              <br className="terms-and-conditions-text194"></br>
            </span>
            </section>
        </Layout>


    )
}

export default TermsAndConditions
