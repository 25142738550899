import React from 'react'
import {Helmet} from 'react-helmet'
import './home.css'

const Layout = ({hero,children}) => {
    return (
        <div className="home-container">
            <Helmet>
                <title>Pressure washer</title>
                <meta property="og:title" content="Pressure washer"/>
            </Helmet>
            <section className="home-hero">
                <header data-thq="thq-navbar" className="home-navbar">
                    <div className="home-left">
                        <img
                            alt="image"
                            src="/logo2.png"
                            className="home-logo"
                        />
                        <nav className="home-links">
                            <a href="/#services" className="home-link">
                                Services
                            </a>
                            <a href="/#why-us" className="home-link01">
                                Why us
                            </a>
                            <a href="/#contact" className="home-link03">
                                Contact
                            </a>
                        </nav>
                    </div>
                    <div data-thq="thq-navbar-btn-group" className="home-right">
                        <span href="#book" className="home-book button button-main">
                            <img
                                alt="image"
                                src="/Icons/phone.svg"
                                className="home-image06"
                            />
                            <span className="home-text06">
                                  <a href="tel:0435665865 " className="home-link15">
                                0435 665 865
                            </a>
                               </span>
                        </span>
                    </div>
                </header>
                {hero}
            </section>
            {children}
            <div className="home-footer">
                <div className="home-left1">
                    <div className="home-brand">
                        <img
                            alt="image"
                            src="/logo2.png"
                            className="home-image19"
                        />
                        <p className="home-text37">
                            Follow us on our social media channels: Find us on Facebook and Instagram for updates, tips,
                            and exclusive content
                        </p>
                    </div>
                    <div className="home-socials">
                        <div className="social">
                            <a href="https://www.instagram.com/jandbpowerwash">
                                <img
                                    alt="image"
                                    src="/Icons/instagram.svg"
                                    className="home-image21"
                                />
                            </a>
                        </div>
                        <div className="social">
                            <a href="https://www.facebook.com/j.and.b.power.wash">
                                <img
                                    alt="image"
                                    src="/Icons/facebook.svg"
                                    className="home-image22"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="home-legal">

                        <span className="legal-link">Privacy Policy</span>
                        <span className="legal-link">Terms of Use</span>
                    </div>
                </div>
                <div className="home-right1">
                    <div className="home-list">
                        <span className="home-header16">Menu</span>
                        <div className="home-links1">
                            <a className="home-link04" href={'/#'}>Home</a>
                            <a className="home-link06" href={'/#services'}>Services</a>
                            <a className="home-link06" href={'/#testimonials'}>Testimonials</a>
                            <a className="home-link08" href={'/#contact'}>Contact</a>
                        </div>
                    </div>
                    <div className="home-list1">
                        <span className="home-header17">Resources</span>
                        <div className="home-links2">
                            <a className="home-link09" href={'/terms'}>Terms & Conditions</a>
                        </div>
                    </div>
                    <div className="home-list2">
                        <a className="home-header18" href={'/#contact'}>Contact</a>
                        <div className="home-links3">
                            {/*<span className="home-link13">*/}
                            {/*  24 Street Name, City FI 01234, RO*/}
                            {/*</span>*/}
                            <a
                                href="mailto:info@jandbpowerwash.com"
                                className="home-link14"
                            >
                                info@jandbpowerwash.com
                            </a>
                            <a href="tel:0435665865 " className="home-link15">
                                0435 665 865
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout
