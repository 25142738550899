import React, {useRef, useState} from "react";

export function Contact() {
    const [submitting, setSubmitting] = useState(false);
    const [alertMessage, setAlertMessage] = useState(null);
    const nameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const _setAlertMessage = (message) => {
        setAlertMessage(message);
        setTimeout(() => {
            setAlertMessage(null);
        }, 5000);
    }
    const descriptionRef = useRef();
    const handleSubmit = async (e) => {
        setSubmitting(true);
        e.preventDefault();

        // Aquí debes hacer la solicitud a la API
        try {
            const response = await fetch('/contact.php', {
                method: 'POST', // O el método que corresponda
                headers: {
                    'Content-Type': 'application/json',
                    // Aquí puedes agregar otros encabezados si es necesario
                },
                body: JSON.stringify({
                    name: nameRef.current.value,
                    email: emailRef.current.value,
                    phone: phoneRef.current.value,
                    description: descriptionRef.current.value,
                }),
            });
            setSubmitting(false);

            const data = await response.json();
            // Aquí debes verificar la respuesta de la API y establecer el mensaje de alerta en consecuencia
            if (data.success) {
                _setAlertMessage("Message sent successfully!");
                e.target.reset();
            } else {
                _setAlertMessage("Failed to send message. Please try again later.");
            }
        } catch (error) {
            console.error('Error:', error);

            _setAlertMessage("An error occurred. Please try again later.");
        }
    };
    return <section id="contact" className="home-book5">
        <div className="home-heading14">
            <h2 className="home-text25">Contact with Us</h2>
            <p className="home-text26">
                Let's Start a Conversation About Your Needs and How We Can Help
            </p>
        </div>
        <form onSubmit={handleSubmit} className="home-form" >

            <div className="home-inputs">
                <input
                    disabled={submitting}
                    ref={nameRef}
                    type="text"
                    placeholder="Name"
                    autoComplete="name"
                    className="input book-input"
                />
                <input
                    disabled={submitting}

                    ref={emailRef}
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    className="input book-input"
                />
                <input
                    disabled={submitting}

                    ref={phoneRef}
                    type="tel"
                    placeholder="Phone"
                    autoComplete="tel"
                    className="input book-input"
                />
                {/*<div className="home-date">*/}
                {/*    <input*/}
                {/*        type="date"*/}
                {/*        placeholder="Date"*/}
                {/*        className="input book-input"*/}
                {/*    />*/}
                {/*</div>*/}
                <input
                    disabled={submitting}

                    ref={descriptionRef}
                    type="text"
                    placeholder="Service description"
                    className="input book-input"
                />
                <div className="home-lower">
                    <p className="home-text29">
                    </p>
                    <div className="home-button">
                        {alertMessage && <div className="alert">{alertMessage}</div>}
                        <button
                            disabled={submitting}
                            className="home-book6 button button-main">
                            <span>{submitting ? 'Sending....' : 'Inquiry'}</span>
                        </button>
                        <p className="home-text31">

                            By clicking Inquiry button, you accept our <a>terms and conditions</a>, which
                            govern the use of our services and products.
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </section>
}