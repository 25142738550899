import React from 'react'

import Features from '../components/features'
import './home.css'
import Layout from "./layout";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import Practice from "../components/practice";
import GalleryCard1 from "../components/gallery-card1";
import TestimonialCard from "../components/testimonials";
import {Contact} from "../components/contact";

const Home = (props) => {
    return (
        <Layout hero={<section className="home-hero">
            <div className="home-main">
                <div className="home-content">
                    <div className="home-heading07">
                        <h1 className="home-header07">
                            <span>PROFESSIONAL PROPERTIES</span>
                            <br></br>
                            <span>WASH SERVICES</span>
                        </h1>
                        <p className="home-caption6">
                            <span>FOR COMMERCIAL AND RESIDENTIAL PROPERTIES</span>
                            <br></br>
                        </p>
                    </div>
                    <button className="button button-main home-book2">
                        <img
                            alt="image"
                            src="/Icons/calendar.svg"
                            className="home-image10"
                        />
                        <span>Inquiry Now!</span>
                    </button>
                </div>
                <div className="home-image11">
                    <img
                        alt="image"
                        src="/pressure-washer.jpg"
                        className="home-image12"
                    />
                </div>
            </div>
            <div id="services" className="home-features">
                <div className="home-content1">
                    <Features title="High Pressure Cleaning Performance">
                        Our pressure washers deliver exceptional cleaning power, removing dirt and grime effectively
                        with precision.
                    </Features>
                    <Features title="Durable and Reliable Machinery">
                        Built to last, our machines boast sturdy construction and reliable performance, ensuring
                        long-term use and minimal maintenance.
                    </Features>
                    <Features title="Versatile Application Capabilities">
                        From residential to industrial settings, our pressure washers adapt to various surfaces and
                        tasks, offering unparalleled versatility.
                    </Features>
                </div>
            </div>
            <div className="home-background"></div>
        </section>}>
            <div className="home-container">
                <Helmet>
                    <title>Pressure washer</title>
                    <meta property="og:title" content="Pressure washer"/>
                </Helmet>

                <section className="home-practices1">
                    <div className="home-heading08">
                        <h2 className="home-text19">Our services</h2>
                        <p className="home-text20">
                            Our Comprehensive Cleaning Solutions
                        </p>
                    </div>
                    <div className="home-content2">
                        <div className="home-grid1">
                            <Link to="/">
                                <div className="home-practice-wrapper">
                                    <Practice title="HOUSE WASH">
                                        Revitalize your home's exterior with our thorough and gentle house washing
                                        services.
                                    </Practice>
                                </div>
                            </Link>
                            <Link to="/">
                                <div className="home-practice-wrapper1">
                                    <Practice title="DRIVEWAYS AND FOOTPATHS">
                                        Restore the pristine look of your driveways and footpaths with our expert
                                        cleaning.
                                    </Practice>
                                </div>
                            </Link>
                            <Link to="/">
                                <div className="home-practice-wrapper2">
                                    <Practice title="PAVER AND PATIOS">
                                        Renew the appeal of your pavers and patios with our professional cleaning
                                        solutions.
                                    </Practice>
                                </div>
                            </Link>
                            <Link to="/">
                                <div className="home-practice-wrapper3">
                                    <Practice title="FENCING">
                                        Enhance the appearance of your fencing with our specialized cleaning techniques.
                                    </Practice>
                                </div>
                            </Link>
                            <Link to="/">
                                <div className="home-practice-wrapper4">
                                    <Practice title="ROOF AND GUTTER">
                                        Safeguard your roof and gutter system with our comprehensive cleaning and
                                        maintenance services.
                                    </Practice>
                                </div>
                            </Link>
                            <Link to="/">
                                <div className="home-practice-wrapper5">
                                    <Practice title="SOLAR PANELS">
                                        Maximize energy efficiency with our solar panel cleaning services for optimal
                                        performance.
                                    </Practice>
                                </div>
                            </Link>
                            <Link to="/">
                                <div className="home-practice-wrapper6">
                                    <Practice title="GRAFFITI REMOVAL">
                                        Eliminate unwanted graffiti and restore the aesthetics of your property with our
                                        prompt removal services.
                                    </Practice>
                                </div>
                            </Link>
                            <Link to="/">
                                <div className="home-practice-wrapper7">
                                    <Practice title="WINDOW CLEANING">
                                        Enjoy crystal-clear views with our meticulous window cleaning services for homes
                                        and
                                        businesses.
                                    </Practice>
                                </div>
                            </Link>
                        </div>
                    </div>
                </section>
                <section id="why-us" className="home-why">
                    <div className="home-heading09">
                        <h2 className="home-header08">Why choose us</h2>
                        <p className="home-header09">
                            Choosing Excellence: Our Commitment to Quality and Satisfaction
                        </p>
                    </div>
                    <div className="home-container2">
                        <div className="home-gallery">
                            <GalleryCard1
                                rootClassName="rootClassName"
                                imageSrc="/before-1500h.jpg"
                            ></GalleryCard1>
                            <GalleryCard1
                                rootClassName="rootClassName1"
                                imageSrc="/after-1500h.jpg"
                            ></GalleryCard1>
                        </div>
                    </div>
                    <div className="home-content3">
                        <div className="home-video"></div>
                        <div className="home-caption7">
                            <h3 className="home-header10">
                                Transformation in Action

                            </h3>
                            <p className="home-header11">
                                Witness the Remarkable Difference Our Services Make
                            </p>
                        </div>
                    </div>
                </section>
                <section className="home-features1">
                    <div className="home-section6">
                        <div className="home-content4">
                            <div className="home-header12">
                                <h2 className="home-heading10">
                                    Experienced Professionals, Professional Tools
                                </h2>
                                <p className="home-capton">
                                    Our seasoned team brings years of expertise and dedication, coupled with
                                    state-of-the-art equipment tailored for precise, efficient results.
                                </p>
                            </div>

                        </div>
                        <img alt="image" src="/floor.jpg" className="home-image14"/>
                    </div>
                    <div className="home-section7">
                        <div className="home-content5">
                            <div className="home-header13">
                                <h2 className="home-heading11">
                                    Power in Every Jet
                                </h2>
                                <p className="home-capton1">
                                    Unleashing Precision to Rejuvenate and Restore Surfaces to Their Former Glory,
                                    Sparkling
                                    with Renewed Brilliance.
                                </p>
                            </div>
                        </div>
                        <img alt="image" src="/roof.jpg" className="home-image16"/>
                    </div>
                    <div className="home-section6">
                        <div className="home-content4">
                            <div className="home-header12">
                                <h2 className="home-heading10">
                                    Commercial Services
                                </h2>
                                <p className="home-capton">
                                    Maintain your business's pristine appearance with our tailored commercial pressure
                                    washing solutions. Impress clients and customers with a spotless exterior that
                                    reflects
                                    professionalism and care. Protect your investment by preventing damage from dirt,
                                    grime,
                                    and pollutants. Reduce slip and fall hazards by eliminating slippery substances like
                                    algae and oil stains. Extend the lifespan of your property's surfaces, saving on
                                    future
                                    repair costs. We use environmentally safe methods and products for guilt-free
                                    cleaning.
                                </p>
                            </div>

                        </div>
                        <img alt="image" src="/wash.jpg" className="home-image14"/>
                    </div>
                </section>
                <div className="home-features1">
                    <a id={'testimonials'} href={'#testimonials'}/>
                    <div className="home-heading09">
                        <h2 className="home-header08">Our clients speak</h2>
                        <p className="home-header09">
                            Choosing Excellence: Our Commitment to Quality and Satisfaction
                        </p>
                    </div>
                    <div className="TestimonialCard">
                        <TestimonialCard
                            name={'Pollyanna Franca'}
                            quote={'I would like to thank Brian for the fantastic service. I had a problem with oil marks in my garage that I was already trying to eliminate personally, without success. Brian came to meet me at the scheduled time and resolved the problem with great professionalism. I recommend the J and B Power Wash service with my eyes closed! I hope to count on you in the next issue :)'}
                            pictureSrc="https://images.unsplash.com/photo-1583195764036-6dc248ac07d9?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE2fHxtYW58ZW58MHx8fHwxNjI2NDUyMDM1&amp;ixlib=rb-1.2.1&amp;h=1200"
                            rootClassName="rootClassName"
                        ></TestimonialCard>
                        <TestimonialCard
                            name={'Cris Ersser'}
                            quote={'I recently hired J and B Power Wash to clean the entrance of my house, and I couldn\'t be happier with the results! Their team was professional, efficient, and thorough. They managed to remove years of dirt and grime, leaving my entrance looking brand new. I was impressed by their attention to detail and commitment to customer satisfaction. I highly recommend [Pressure Wash Company Name] for anyone in need of top-notch pressure washing services!'}
                            pictureSrc="https://images.unsplash.com/photo-1566492031773-4f4e44671857?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDIzfHxtYW58ZW58MHx8fHwxNjI2NDUyMDM1&amp;ixlib=rb-1.2.1&amp;h=1200"
                            rootClassName="rootClassName"
                        ></TestimonialCard>
                        <TestimonialCard
                            name={'Tiffany J.'}
                            quote={'I\'m thrilled to share my fantastic experience with J and B Power Wash after they recently revitalized the exterior of my home!... So happy with the result. Highly recommended.'}
                            pictureSrc="https://images.unsplash.com/photo-1546456073-ea246a7bd25f?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDh8fGJsYWNrJTIwbWFufGVufDB8fHx8MTYyNjQ1MjAwOA&amp;ixlib=rb-1.2.1&amp;h=1200"
                            rootClassName="rootClassName"
                        ></TestimonialCard>
                    </div>
                </div>
                <section id="schedule" className="home-schedule">
                    <div className="home-content7">
                        <div className="home-header15">
                            <h2 className="home-heading13">
                                Contact Us to Understand Your Needs and Receive a Free Quote Today
                            </h2>
                            <p className="home-caption8">
                                At Contact Us, we're dedicated to providing tailored solutions. Reach out for
                                personalized
                                assistance and discover the difference
                            </p>
                        </div>
                        <div className="home-types"></div>
                    </div>
                </section>
                <Contact/>
            </div>
        </Layout>

    )
}

export default Home
